import {Link} from 'gatsby'
import React from 'react'
import GalleryItem from "./gallery-item";

import styles from './blog-post-preview-grid.module.css'

function GalleryGrid (props) {
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
        props.nodes.map(node => (
          <li key={node.id}>
            <GalleryItem {...node} />
          </li>
        ))}
      </ul>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  )
}

GalleryGrid.defaultProps = {
  nodes: []
}

export default GalleryGrid
