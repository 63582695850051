import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './blog-post-preview.module.css'

function GalleryItem (props) {
  return (
    <div className={styles.leadMediaThumb}>
      {props.mainImage && props.mainImage.asset && (
        <img
          src={imageUrlFor(buildImageObj(props.mainImage))
            .width(600)
            .height(Math.floor((9 / 16) * 600))
            .auto('format')
            .url()}
          alt={props.mainImage.alt}
        />
      )}
    </div>
  )
}

export default GalleryItem
