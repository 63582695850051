import React from 'react'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes} from '../lib/helpers'
import Container from '../components/container'
import GalleryGrid from "../components/gallery-grid";
import GraphQLErrorList from '../components/graphql-error-list'

import {responsiveTitle1} from '../components/typography.module.css'
import {graphql} from "gatsby";

export const query = graphql`
  query GalleryPageQuery {
    posts: allSanityPhoto(sort: {fields: _createdAt, order: DESC}) {
      edges {
        node {
          id
          name
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const GalleryPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const postNodes = data && data.pics && mapEdgesToNodes(data.pics)

  return (
    <Layout>
      <SEO title='Gallery' />
      <Container>
        <h1 className={responsiveTitle1}>Gallery</h1>
        <h2>Coming Soon.</h2>
        {postNodes && postNodes.length > 0 && <GalleryGrid nodes={postNodes} />}
      </Container>
    </Layout>
  )
}

export default GalleryPage
